// components/SideNavbar.js

import styles from '../../styles/SideNavbar.module.css';
import { useState } from 'react';
import { useRouter } from 'next/router';

const SideNavbar = ({isOpen, closeSidebar }) => {
    const router = useRouter();
    const { id } = router.query;
    const [isHovered, setIsHovered] = useState(false);
    const [isClicked, setClicked] = useState(false);
    


  const categories = [
    { name: "Home", iconUrl: "/assets/sidenav/home.svg", pageUrl: "/" },
    { name: "Exclusive Games", iconUrl: "/assets/sidenav/exclusive.svg", pageUrl: "exclusive-games" },
    { name: "New Games", iconUrl: "/assets/sidenav/new-games.svg", pageUrl: "new-games" },
    { name: "Christmas games", iconUrl: "/assets/sidenav/christmas.svg", pageUrl: "christmas-games" },
    { name: "Halloween games", iconUrl: "/assets/sidenav/halloween.svg", pageUrl: "halloween-games" },
    { name: "Princess Games", iconUrl: "/assets/sidenav/princess.svg", pageUrl: "princess-games" },
    { name: "Dress up Games", iconUrl: "/assets/sidenav/dressup.svg", pageUrl: "dress-up-games" },
    { name: "Make Up Games", iconUrl: "/assets/sidenav/makeup.svg", pageUrl: "makeover-games" },
    { name: "Decoration Games", iconUrl: "/assets/sidenav/decoration.svg", pageUrl: "decoration-games" },
    { name: "Wedding Games", iconUrl: "/assets/sidenav/wedding.svg", pageUrl: "wedding-games" },
    { name: "Celebrity Games", iconUrl: "/assets/sidenav/celebrity.svg", pageUrl: "celebrity-games" },
    { name: "Cooking Games", iconUrl: "/assets/sidenav/cooking.svg", pageUrl: "cooking-games" },
    { name: "Doctor Games", iconUrl: "/assets/sidenav/doctor.svg", pageUrl: "doctor-games" },
    { name: "FNF Games", iconUrl: "/assets/sidenav/friday-night-funkin.svg", pageUrl: "friday-night-funkin" },
    // { name: "Prinxy Games", iconUrl: "/assets/sidenav/icons.png", pageUrl: "prinxy-games" },
    { name: "Winx club Games", iconUrl: "/assets/sidenav/icons.png", pageUrl: "winx-club-games" },
    // { name: "Girls Go Games", iconUrl: "/assets/sidenav/icons.png", pageUrl: "girlsgogames" },
    { name: "Shopaholic Games", iconUrl: "/assets/sidenav/shopaholic.svg", pageUrl: "shopaholic-games" },
    { name: "My Dolphin Show", iconUrl: "/assets/sidenav/dolphin.svg", pageUrl: "my-dolphin-show" },
    // { name: "Gamezop Games", iconUrl: "/assets/sidenav/gamezop.png", pageUrl: "gamezop-games" },
    { name: "View All Tag", iconUrl: "/assets/sidenav/all-tag.svg", pageUrl: "all-categories" },
    // { name: "Blog", iconUrl: "/assets/sidenav/blogs.svg", pageUrl: "blog" },
    // { name: "Dl-Girls Games", iconUrl: "/assets/sidenav/icons.png", pageUrl: "dl-girls" },

    
  ];
 
//   console.log(`Current: ${router.pathname}, Category: ${category.name}, URL: ${category.pageUrl}, isActive: ${isActive}`);



const CurretnPath = () => {
  return `/categories/${{ id }.id}`;
};



return (


<>
      {isOpen && (<div className='contant name'> Is open </div>)}
      {(isOpen || isHovered) && <div className={styles.overlay} onClick={closeSidebar}></div>}
    <div className={styles.sideNavbar} style={{
      width:(isOpen || isHovered) ? '200px' : '0px', // Mobile handling
      transition: 'width 0.3s ease', 
    }}
    onClick={closeSidebar}
      
        onMouseEnter={() => setIsHovered(true)}  // Show overlay on hover
        onMouseLeave={() => setIsHovered(false)} // Hide overlay when hover stops
    >
      <ul>
        {categories.map((category, index) => {
          const isActive = category.pageUrl === "/" ? router.pathname === "/" :CurretnPath() === `/categories/${category.pageUrl}`;
          const isAlltag = category.pageUrl === "all-categories" ? router.pathname === "/all-categories" :CurretnPath() === `/categories/${category.pageUrl}`;
          const isBlog = category.pageUrl === "blog" ? router.pathname === "/blog" :CurretnPath() === `/categories/${category.pageUrl}`;
           

          return (
            <li
            
              key={index}
              className={`${styles.categoryItem} ${isActive ? styles.active : ''} ${isAlltag ? styles.active : ''} ${isBlog ? styles.active : ''} `}
              onClick={() => {
                {isOpen && setIsHovered(false)}
                {!isOpen && setClicked(true)}
                router.push(category.pageUrl === "/" ? "/" : category.pageUrl === "all-categories" ? "/all-categories" : category.pageUrl === "blog" ? "/blog" :`/categories/${category.pageUrl}`)}}>
              <img src={category.iconUrl} alt={category.name} className={styles.icon} />
              <span className={styles.categoryName}>{category.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
    </>
  );
};

export default SideNavbar;
